import React, { Component } from 'react'

import SEO from '../../components/SEO/SEO'
import Layout from '../../Layout/Layout'
import Hero from '../../components/Hero/Hero'
import Header from '../../components/Header/Header'
import Subheader from '../../components/Subheader/Subheader'
import Container from '../../components/Container/Container'

import ImageHero from '../../assets/images/thumb3.jpg'

class BlogPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Bezpieczeństwo w trakcie pracy na wysokości. 5 najważniejszych wskazówek."
          keywords={[
            'prace na wysokosciach',
            'zwyzka koszowa',
            'wynajem podnosnika rzeszow',
            'zwyzka stalowa wola',
            'trnobrzeg wynajem zwyzki',
            'podkarpacie podnosnik',
            'zwyzka teleskopowa',
            'mycie okien',
          ]}
        />
        <Hero image={ImageHero}>
          <Header>
            Bezpieczeństwo w trakcie pracy na wysokości. 5 najważniejszych
            wskazówek.
          </Header>
          <Subheader>
            Praca na wysokości to ogromne ryzyko dla naszego życia i zdrowia.
            Więcej niż połowa wypadków ciężkich i śmiertelnych w budownictwie
            rejestrowanych przez Państwową Inspekcję Pracy to właśnie upadki z
            wysokości. Zdarzają się wypadki i zdarzenia losowe, na które nie
            mamy wpływu, jednak większości tych zdarzeń można zapobiec. Należy
            zawsze pamiętać o zasadach bezpiecznej pracy na zwyżkach,
            podnośnikach, rusztowaniach, dachach i innych wysoko położonych
            elementach.
          </Subheader>
        </Hero>
        <Container columns={1}>
          <Header as="h3">Praca na wysokości - zabezpieczenia</Header>
          <p>
            Te smutne statystyki dotyczące pracy na wysokości wynikają głównie z
            faktu, że polscy pracodawcy zapominają o podstawowej zasadzie
            bezpieczeństwa i higieny pracy obowiązującej w całej Europie, jaką
            jest pierwszeństwo środków ochrony zbiorowej nad środkami ochrony
            indywidualnej. Mówiąc prościej należy w pierwszej kolejności,
            zwłaszcza jeśli chodzi o pracę na wysokości, stosować takie
            zabezpieczenia, które nie zależą od woli pojedynczego pracownika, a
            więc bariery, pomosty zabezpieczające, siatki bezpieczeństwa. Środki
            ochrony indywidualnej jak szelki bezpieczeństwa można stosować
            dopiero w sytuacji, kiedy środki ochrony zbiorowej nie są możliwe do
            zastosowania.
          </p>
          <p>
            W Polsce pracodawcy przyjęli, że te zabezpieczenia do pracy na
            wysokości, które są tańsze i szybsze w zastosowaniu są
            najwłaściwsze. Doprowadza to bardzo często do sytuacji, w której
            pracownik jest wyposażony w szelki bezpieczeństwa, dla spokoju
            bierze je także na dach, ale ich nie stosuje. Zgodnie z polskimi
            przepisami odpowiedzialność w zakresie bezpieczeństwa i higieny
            pracy za taką sytuację ponosi osoba nadzorująca pracę, a więc
            pracodawca czy kierownik budowy i nie mają znaczenia tłumaczenia, że
            nie ma możliwości przypilnowania każdego pracownika z osobna.
          </p>

          <Header as="h3">Praca na wysokości dekarze</Header>
          <p>
            Dekarze z założenia pracują na wysokości, m.in. na powierzchni
            dachu, a szelki mają ich zabezpieczać w sytuacji awaryjnej, jaką
            jest upadek poza obrys dachu. Właściwy zatem jest sprzęt najprostszy
            i tym samym najlżejszy i najmniej ograniczający ruchy. Kolejnym
            elementem mającym wpływ na decyzję pracownika o zastosowaniu szelek
            jest wskazanie mu miejsca mocowania szelek. Nie wystarczy wyposażyć
            pracownika na dole w szelki. Należy zadbać, aby pracownik dokładnie
            wiedział, do jakiego elementu może je przymocować. Jeżeli nie ma
            takiego elementu w konstrukcji budynku, to należy zapewnić właściwie
            zamocowane kotwy. Przestrzec należy w tym miejscu przed
            przywiązywaniem linek bezpieczeństwa do kominów. Komin jest
            konstrukcją, która nie przenosi sił ścinających, jakie występują
            podczas przytrzymywania osoby spadającej. Jeżeli jest to komin w
            miarę nowy, właściwie skonstruowany, to może wytrzymać takie
            obciążenie, ale jeżeli mamy do czynienia z kominem starym, którego
            cegły trzymają się razem bardziej "z przyzwyczajenia" niż z powodu
            wiązania, to taki element nie tylko nie zatrzyma spadającego
            pracownika, ale może jeszcze spaść na niego powodując dodatkowe
            obrażenia ciała.
          </p>
          <p>
            Zgodnie z przepisami na dachach, których nachylenie jest powyżej 20%
            wszyscy przebywający muszą być zabezpieczeni przed upadkiem z
            wysokości. Natomiast na dachach o nachyleniu do 20% zabezpieczeni
            powinni być ci pracujący, którzy przebywają w pobliżu krawędzi
            dachu. Nigdzie w przepisach nie zostało sprecyzowane co oznacza „w
            pobliżu krawędzi dachu”. Należy w tym miejscu zwrócić jednak uwagę,
            że osoby nadzorujące nigdy nie będą miały pewności, czy pracownik,
            który pracę wykonuje w kalenicy nie podejdzie do krawędzi dachu, aby
            porozumieć się z osobą znajdującą się na dole. Nawet jeżeli nie
            zrobi tego świadomie, to sytuacja panująca na dachu może spowodować,
            że wbrew swojej woli znajdzie się w sytuacji zagrożenia. Do takiego
            wypadku doszło np. w 2008 roku w Łodzi, gdzie pracownicy niosący
            arkusz blachy zostali zepchnięci z dachu przez nagły poryw wiatru.
          </p>

          <Header as="h3">
            Praca na wysokości - bezpieczeństwo pracowników
          </Header>
          <p>
            Przedstawiamy ważne wskazówki dla osób odpowiedzialnych za
            bezpieczeństwo pracowników:
          </p>
          <ol>
            <li>
              sprawdź, czy możliwe jest zastosowanie środków ochrony zbiorowej
              tj. barier, rusztowań lub pomostów zabezpieczających, siatek
              bezpieczeństwa – wystarczy na początku każdej dniówki sprawdzić,
              czy są właściwie skonstruowane i zamocowane, a nie będziesz musiał
              polegać na odpowiedzialności swoich podwładnych;
            </li>
            <li>
              jeżeli zastosowanie środków ochrony zbiorowej nie jest możliwe,
              wyposaż wszystkich swoich pracowników pracujących na dachu,
              niezależnie od stopnia nachylenia dachu, w środki ochrony
              indywidualnej tj. szelki bezpieczeństwa z linką i amortyzatorem;
            </li>
            <li>
              zapewnij, aby te środki były właściwie dobrane do wykonywanej
              pracy, wówczas unikniesz sytuacji, w której pracownicy za twoimi
              plecami będą zdejmować szelki;
            </li>
            <li>
              upewnij się, że pracownicy wiedzą, do czego mają przypiąć linki i
              że miejsca mocowania są wystarczająco wytrzymałe;
            </li>
            <li>
              pamiętaj, że jesteś odpowiedzialny za bezpieczeństwo swoich
              podwładnych – od ich zachowania może zależeć twoja przyszłość, a
              więc przeszkol ich właściwie, zadbaj, aby byli świadomi zagrożeń.
            </li>
          </ol>

          <Header as="h3">Praca na wysokości - niebezpieczne strefy</Header>
          <p>
            Kolejnym problemem najczęściej występującym przy pracach dekarskich
            są strefy niebezpieczne, czyli strefy, w których istnieje ryzyko
            upadku przedmiotów z wysokości. Zgodnie z obowiązującymi przepisami
            podczas prowadzenia prac na wysokości należy wygrodzić na poziomie
            terenu strefę niebezpieczną, której szerokość wynosi min. 0,1
            wysokości, na której prowadzone są prace, lecz nie mniej niż 6 m.
          </p>
          <p>
            W warunkach nieprzemysłowych przy robotach dekarskich obowiązuje
            więc strefa 6 m. Należy o tym pamiętać przed podjęciem się
            realizacji umowy, bowiem należy sprawdzić, do kogo należy teren o
            szer. 6 m wokół budynku i uzgodnić z właścicielem warunki zajęcia go
            przez strefę niebezpieczną. Jeżeli w jej obrębie znajduje się
            chodnik dla pieszych lub parking, należy zadbać o właściwe
            oznakowania informujące osoby postronne o prowadzonych pracach na
            wysokości i związanym z tym zakazie wstępu. Jeżeli w obrębie
            znajduje się droga lub ulica, to z zarządcą należy ustalić warunki
            zamknięcia lub zawężenia drogi.
          </p>
          <p>
            Strefa niebezpieczna powinna być wygrodzona w miarę trwale, aby nie
            prowokować osób postronnych, zazwyczaj mocno niezadowolonych z
            utrudnień, do niszczenia wygrodzenia i wchodzenia pomimo zakazu do
            strefy. Popularne taśmy niestety są niszczone przez mieszkańców, a
            za trwałość wygrodzenia i oznakowania odpowiedzialny jest wykonawca
            robót. Różnica jest bardzo istotna w zakresie odpowiedzialności
            karnej, a także cywilnej. Jeżeli strefa jest właściwie wygrodzona i
            oznakowana, a ktoś wejdzie w strefę, to robi to na własną
            odpowiedzialność. Jeżeli natomiast znajdzie się w strefie
            niewłaściwie zabezpieczonej, to wówczas odpowiedzialność za jego
            bezpieczeństwo, a także za bezpieczeństwo np. samochodu,
            zaparkowanego w strefie ponosi wykonawca robót. Może zatem zdarzyć
            się tak, że przy niewłaściwym oznakowaniu i wygrodzeniu kierowca
            zaparkuje w strefie niebezpiecznej samochód, na który spadnie z góry
            narzędzie lub materiał. Wówczas wykonawca będzie musiał pokryć
            straty z własnej kieszeni. Jeżeli natomiast kierowca zaparkuje w
            strefie, pomimo właściwego oznakowania i wygrodzenia, wówczas straty
            musi pokrywać z własnego AC.
          </p>

          <Header as="h3">Warto pamiętać!</Header>
          <p>
            Strefa niebezpieczna w świetle przepisów obejmuje obszar min. 6 m.
            Nie można zatem liczyć, że jeżeli wygrodzi się tylko 4 m, to za
            pozostałe 2 m nie będzie się odpowiadało.
          </p>

          <Header as="h3">Praca na wysokości - praktyczne rady</Header>
          <ul>
            <li>
              przed podpisaniem umowy rozważ do kogo należy strefa 6 m wokół
              budynku i na co jest przeznaczona;
            </li>
            <li>
              przed przystąpieniem do robót uzgodnij z właścicielem terenu
              warunki wyłączenia tego terenu z użytkowania przez osoby
              postronne;
            </li>
            <li>
              wygrodź strefę o szer. min. 6 m, ponieważ za taką odpowiadasz;
            </li>
            <li>
              zadbaj, aby wygrodzenie było w miarę trwałe i odpowiednio
              oznakowane – unikniesz codziennej naprawy np. pozrywanych taśm i
              wkraczania osób postronnych w strefę, za którą odpowiadasz.
            </li>
          </ul>
          <p>
            Uwaga! Przestrzeganie przepisów może uchronić Cię przed
            odpowiedzialnością za ewentualne zdarzenia, na które nie zawsze masz
            wpływ.
          </p>
        </Container>
      </Layout>
    )
  }
}

export default BlogPage
